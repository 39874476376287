import React, { useState, useRef } from "react";
function ChatMessages(props) {
  const [inputText, setInputText] = useState("");
  const [status, setStatus] = useState("idle");
  const [Messages, setMessages] = useState([]);

  const submitText = () => {
    let url = "https://www.zaojingyoutu.top:8002/api/chat-ai/";
    props.onMessage({ text: inputText, sender: "human" });
    setInputText("");
    setStatus("pending");
    textareaRef.current.style.height = "auto";
    Messages.push({
      "role": "user",
      "content": inputText
    });
    const token = localStorage.getItem('token');
    
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        "stream": "stream",
        prompt: {
          "messages": Messages
        },
      }),
    };

    fetch(url, options)
      .then((response) => {
        if (response.status === 401) {
          props.handleLogin('block');
          setMessages([])
          setStatus("");
          alert("登录过期，请重新登录");
          return
        }
        // 获取 reader 对象
        const reader = response.body.getReader();
        let index = 0;
        // 定义一个解码器
        const decoder = new TextDecoder();
        let raw_resp = [];
        let msgStr = "";
        // 定义一个循环函数
        function read() {
          let msg = [];
          // 读取数据块
          reader.read().then(({ value, done }) => {
            // 如果读取完毕，退出循环
            if (done) {
              Messages.push({
                content: msgStr,
                role: "assistant"
              });
              return;
            }
            // 将数据块转换为字符串
            const chunk = decoder.decode(value);
            raw_resp.push(chunk)
            const lines = raw_resp.join('').split("data:");
            for (const line of lines) {
              if (line === "" || line.startsWith(":")) {
                continue;
              }
              try {
                msg.push(JSON.parse(line).result);
                msgStr = msg.join('');
                props.onMessage({
                  index: ++index,
                  text: msgStr,
                  sender: "assistant",
                });
              } catch {
                continue;
              }
            }

            if (lines[0].slice(0, 7).includes('error')) {
              props.onMessage({
                index: ++index,
                text: JSON.stringify(lines),
                sender: "assistant",
              });
            }
            read();
          });
        }
        // 开始循环读取
        read();
        setStatus("success");
      })
      .catch(() => setStatus("error"));
  };
  const textareaRef = useRef(null);
  const maxHeight = 100;
  const onChange = (e) => {
    setInputText(e.target.value);
    const textarea = textareaRef.current;
    let heightDiff = textarea.scrollHeight - textarea.clientHeight;
    if (heightDiff > 0) {
      textarea.style.height = Math.min(textarea.scrollHeight, maxHeight) + "px";
    }
  };
  const clear = () => {
    setMessages([]);
    setStatus("");
    props.clear();
  }


  return (
    <div
      style={{
        display: "flex",
        width: "40%",
        height: "7%",
        justifyContent: "center",
        position: "absolute",
        alignItems: "center",
        left: "31%",
        paddingBottom: "2%",
        bottom: "0",
        zIndex: "999",
      }}
    >
      <button onClick={clear} style={{ fontSize: "1.2em", backgroundColor: "transparent", border: "none" }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1.01em" height="1em" viewBox="0 0 1024 1023"><path fill="currentColor" d="M1022 454q0 21-12 39t-29 19H541q-31 1-30-46l157-156q-69-54-156-54q-106 0-181 75t-75 181t75 180.5T512 767q58 0 109-24t87-67l201 157q-71 89-175 139.5T512 1023q-104 0-199-40.5t-163.5-109T40.5 710T0 511t40.5-198.5t109-163T313 40.5T512 0q95 0 182 33.5T850 128L978 0q47-1 46 30z" /></svg>
      </button>
      <textarea
        type="text"
        ref={textareaRef}
        value={inputText}
        onChange={onChange}
        style={{
          width: "auto",
          flexGrow: 1,
          minWidth: 200,
          fontSize: "x-large",
          margin: "5px",
          border: "1px solid black",
          borderRadius: "10px",
          height: '100%',
        }}
        onKeyDown={(e) => {
          if (
            e.key === "Enter" &&
            e.ctrlKey === false &&
            e.shiftKey === false &&
            e.altKey === false
          ) {
            submitText();
          }
        }}
      />

      <button onClick={submitText}>发送</button>
      <div style={{ padding: "5px 10px" }}>
        {" "}
        {status === "pending" && <div>等待中...</div>}
        {status === "success" && <div>发送成功!</div>}
        {status === "error" && <div>发送失败!</div>}
      </div>
    </div>
  );
}

export default ChatMessages;
